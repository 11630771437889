/* Global styles */
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

/* Custom scrollbar for WebKit browsers */
html, body {
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #6c757d #b4aaaa25; /* Firefox */
}

html::-webkit-scrollbar {
    width: 2px; /* Slim width of the scrollbar */
}

html::-webkit-scrollbar-track {
    background: #f0f0f0; /* Light color of the scrollbar track */
}

html::-webkit-scrollbar-thumb {
    background-color: #6c757d; /* Main color of the scrollbar thumb */
    border-radius: 10px; /* Smooth roundness of the scrollbar thumb */
    border: 1px solid #f0f0f0; /* Thin border around the thumb for separation */
}

html::-webkit-scrollbar-thumb:hover {
    background-color: #495057; /* Darker color of the scrollbar thumb on hover */
}
